import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})

export default class LazyImage extends Vue {
  @Prop({ required: true }) src!: string;
  @Prop({ required: true }) srcPlaceholder!: string;
  @Prop({ required: true }) alt!: string;

  imgLoadError(e): void {
    // eslint-disable-next-line no-console
    console.error('error', e);
    e.target.classList.add('v-lazy-image-loaded');
  }
}
